<template>
    <div class="format-item__list">
        <div class="format-item">
            <div class="format-item__inc">
                <div class="format-item__image">
                    <img src="/images/course-landing/choose-format-key1.png">
                </div>
                <div class="format-item__uptitle">
                    Тариф
                </div>
                <div class="format-item__title noBorder">
                    Cеребряный ключик
                </div>

                <div class="format-item__content-list">
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-2/3-8.png">
                        </div>
                        <div class="format-item__content-text">
                            Более 20-ти мощных уроков на тему бесплодия и материнства
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-2/1-2.svg">
                        </div>
                        <div class="format-item__content-text">
                            групповых исцеляющих медитаций для проработки своих запросов
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-2/1-4.svg">
                        </div>
                        <div class="format-item__content-text">
                            эффективных техник и практик для проработки своих запросов
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-2/1-5.svg">
                        </div>
                        <div class="format-item__content-text">
                            урока по нейрографике и арт-терапии для проработки страхов
                        </div>
                    </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-2/1-7.svg">
                        </div>
                        <div class="format-item__content-text">
                            доступ к дополнительным материалам <br>
                            <span class="small">
                            (метафорические карты, скрипты для индивидуальных терапий,
                                чек листы, презентации, конспекты уроков)
                        </span>
                        </div>
                    </div>
                  <div class="format-item__content-list-item">
                    <div class="format-item__content-image">
                      <img src="/images/course-landing/format-images-2/1-9.svg">
                    </div>
                    <div class="format-item__content-text">
                      <strong>модуля</strong> обучения
                    </div>
                  </div>
                    <div class="format-item__content-list-item">
                        <div class="format-item__content-image">
                            <img src="/images/course-landing/format-images-2/3-1.svg">
                        </div>
                        <div class="format-item__content-text">
                            <strong>
                               12 месяцев
                            </strong>
                            обучения
                        </div>
                    </div>
                </div>

                <div class="format-item__price-block">
                    <div class="format-item__price-title">Купить в  рассрочку от</div>
                    <div class="format-item__price-current">
                        4166  ₽ <span class="small">/мес</span>
                    </div>
                    <!--<div class="format-item__price-title">Или внести предоплату</div>-->
                    <!--<div class="format-item__price-current">5 000 ₽</div>-->
                    <div class="format-item__price-title">Или внести сразу всю сумму</div>
                    <span class="format-item__price-current">25 000 ₽</span>
                    <!--<span class="format-item__price-old">39 900 ₽</span>-->
                </div>
                <button
                        class="es-button black"
                        @click="showPaymentFormSilver()"
                >
                    Продолжить
                </button>
                <div class="format-item__discleimer">
                    *переходите по кнопке, чтобы выбрать способ оплаты
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'CourseFormatItem',
  data() {
    return {
      isGoldTermsHidden: true,
      isDiamondTermsHidden: true,
    };
  },
  computed: {
    isShortPage() {
      return this.$route.name === 'Courseland20Short';
    },
  },
  mounted() {
    if (window.innerWidth > 767) {
      this.isGoldTermsHidden = false;
      this.isDiamondTermsHidden = false;
    }
  },
  methods: {
    showPaymentFormSilver() {
      this.$store.commit('setCurrentTemplate', 'get-course-form-silver');
      this.$store.commit('showDialog');
    },
    showPaymentFormGold() {
      this.$store.commit('setCurrentTemplate', 'get-course-form-gold');
      this.$store.commit('showDialog');
    },
    showPaymentFormDiamond() {
      this.$store.commit('setCurrentTemplate', 'get-course-form-diamond');
      this.$store.commit('showDialog');
    },
    showGoldAdvatages() {
      this.isGoldTermsHidden = false;
    },
    showDiamondAdvatages() {
      this.isDiamondTermsHidden = false;
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.format-item {
    background: rgba(255,255,255,0.4);
    box-shadow: 0 4px 13px rgba(125, 137, 161, 0.19);
    border-radius: 20px;
    padding: 8px;
    font-family: $titleFont;
    margin-bottom: 40px;

    @media only screen and (min-width: 767px) {
        margin-right: 15px;
        flex-shrink: 0;
        width: 280px;
    }

    &__list {
        @media only screen and (min-width: 767px) {
            display: flex;
            overflow-x: auto;
            justify-content: center;

        }

        @media only screen and (min-width: 1024px) {
            position: relative;
            left: 30px;
        }
    }

    &:last-child {
        margin-bottom: 25px;

        @media only screen and (min-width: 767px) {
           margin-bottom: 40px;
        }
    }

    &:nth-child(2) {
        .format-item__image img {
            margin-right: -59px;
            margin-bottom: 12px;
        }
    }

    &__inc {
        background: #FFFFFF;
        box-shadow: 0 4px 13px rgba(125, 137, 161, 0.19);
        border-radius: 20px;
        padding: 13px;
        min-height: calc(100% - 26px);

        &.dark {
            background: #E4E8F5;

            .format-item__content-title {
                color: $landingBlue;
            }

            .format-item__price-old {
                color: #7D89A1;
            }
        }
    }

    &__image {
        text-align: center;

        @media only screen and (min-width: 767px) {
            display: flex;
            height: 161px;
            align-items: center;
            justify-content: center;
        }

        img {
            margin-right: -45px;
            max-width: 164px;
        }
    }

    &__uptitle {
        width: 66px;
        height: 28px;
        background: $landingBlue;
        border-radius: 15px;
        margin: 0 auto;
        line-height: 28px;
        font-weight: 400;
        font-size: 12px;
        color: $white;
        text-align: center;
    }

    &__title {
        font-weight: 500;
        font-size: 20px;
        line-height: 120%;
        position: relative;
        padding-bottom: 25px;
        margin: 3px 0 18px 0;
        text-align: center;

        @media only screen and (min-width: 767px) {
            white-space: nowrap;
            margin-bottom: 30px;
        }

        &.noBorder {
            padding-bottom: 0;
            margin-bottom: 15px;

            &:after {
                display: none;
            }
        }
    }

    &__content-title {
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: $landingGray;
    }

    &__modules-item {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        position: relative;
        display: flex;
        margin-bottom: 18px;
        align-items: center;
    }

    &__availability {
        font-size: 14px;
        line-height: 120%;
        letter-spacing: 0.02em;
        margin: 15px 0 25px 0;
        text-align: center;
        color: #7D89A1;
    }

    &__modules-item-count {
        font-weight: 500;
        font-size: 26px;
        line-height: 18px;
        color: #538BFF;
        margin-right: 15px;
    }

    &__modiles-list {
        margin: 15px 0;
    }

    &__content-list {
        margin: 15px 0;

        &.additionalList {
            @media only screen and (min-width: 767px) {
                display: none
            }
        }
    }

    &__content-list-collapser {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        position: relative;
        border: 1px solid #D1D6E7;
        border-radius: 20px 20px 0 0;
        padding: 20px 40px 20px 20px;
        cursor: pointer;
        color: #292935;
        margin-bottom: 15px;

        &.gray {
            @media only screen and (max-width: 767px) {
                background: #F3F6FC;
            }
        }

        @media only screen and (min-width: 767px) {
            display: none
        }

        &:after {
            background: url("/images/course-landing/arrow-bottom-gray.svg") no-repeat;
            width: 13px;
            height: 7px;
            position: absolute;
            top: 50%;
            margin-top: -4px;
            right: 18px;
            content: '';
        }
    }

    &__content-image {
        img {
            max-width: 45px;
        }
    }

    &__content-list-item {
        display: flex;
        gap: 19px;
        align-items: center;
        border: 1px solid #D1D6E7;
        padding: 15px 20px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #292935;
        border-top: 0;

        &:first-child {
            border-radius: 20px 20px 0 0;
            border-top: 1px solid #D1D6E7;
        }

        &:last-child {
            border-radius: 0 0 20px 20px;
        }

        &.noBorderRadius {
            border-radius: 0;
        }

        .small {
            font-size: 10px;
            line-height: 14px;
            display: block;
            margin-top: 5px;
        }

        strong {
            font-weight: 600;

            &.blue {
                color: $main-blue;
            }
        }

        &.gray {
            background: #F3F6FC;
        }

        &.mobileGray {
            @media only screen and (max-width: 767px) {
                background: #F3F6FC;
            }
        }

        &.mobileHidden {
            display: none;

            @media only screen and (min-width: 767px) {
                display: flex;
            }
        }

        &.fixedHeight115 {
            @media only screen and (min-width: 767px) {
                height: 85px;
            }
        }

        &.fixedHeight230 {
            @media only screen and (min-width: 767px) {
                height:198px;
            }
        }

        &.minus {
            position: relative;

            &:before {
                position: absolute;
                background: url("/images/course-landing/minus-icon-gray-large.svg");
                width: 22px;
                height: 22px;
                left: calc(50% - 11px);
                top: calc(50% - 11px);

                @media only screen and (min-width: 767px) {
                    content: '';
                }
            }

            @media only screen and (min-width: 767px) {
                .format-item__content-text {
                    opacity: 0;
                }

                .format-item__content-image {
                    opacity: 0;
                }
            }
        }

        &.plus {
            position: relative;

            &:before {
                position: absolute;
                background: url("/images/course-landing/plus-icon-blue-large.svg");
                width: 22px;
                height: 22px;
                left: calc(50% - 11px);
                top: calc(50% - 11px);

                @media only screen and (min-width: 767px) {
                    content: '';
                }
            }

            @media only screen and (min-width: 767px) {
                .format-item__content-text {
                    opacity: 0;
                }

                .format-item__content-image {
                    opacity: 0;
                }
            }
        }
    }

    &__plus-content-list {
        font-size: 40px;
        line-height: 120%;
        text-align: center;
        color: $landingBlue;

        @media only screen and (min-width: 767px) {
            display: none;
        }
    }

    &__price-block {
        margin: 40px 0 25px 10px;
    }

    .es-button--link.es-button--link.lh20 {
        line-height: 20px;
    }

    &__price-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 120%;
        margin-bottom: 6px;
        margin-top: 15px;
    }

    &__price-old {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-decoration-line: line-through;
        color: $landingGray;
        margin: 2px 0;
        margin-left: 8px;
    }

    &__price-current {
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
        color: #292935;

        .small {
            font-size: 20px;
        }
    }

    &__discleimer {
        letter-spacing: 0.02em;
        color: rgba(46, 59, 95, 0.4);
        font-weight: 500;
        font-size: 10px;
        line-height: 120%;
        margin: 20px 0 20px;
    }

    .es-button {
        border-radius: 40px;

        &:disabled {
            background: #D1D6E7;
        }
    }
}
</style>
